import Vue, { VueConstructor } from 'vue';
import { Loading } from 'element-ui';
import { ElLoadingComponent } from 'element-ui/types/loading';

import { UserWindowSize } from '@/classes/definition/UserWindowSize';

/** 画面サイズ情報 */
export const $_windowSize = Vue.observable<UserWindowSize.WindowSizeData>({
  ratio:  1,
  width:  window.innerWidth,
  height: window.innerHeight
});

/**
 * WindowAdjustPlugin
 */
export default {
  install(Vue: VueConstructor) {
    /** リサイズタイマー */
    let timer = -1;

    /** element-ui loading */
    let loadingInstance: ElLoadingComponent;

    /** loader表示処理 */
    const openLoading = (): void => {
      loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 1)',
        customClass: 'windowAdjustLoading'
      });
    };

    /** loader非表示処理 */
    const closeLoading = (): void => {
      if (loadingInstance !== undefined) {
        setTimeout(() => loadingInstance.close(), 200);
      }
    };

    /** 画面サイズ調整処理 */
    const adjustWindow = (): void => {
      let targetWidth  = 0;
      let targetHeight = 0;
      if      (window.outerWidth === 0)  targetWidth  = window.innerWidth;
      else if (window.innerWidth === 0)  targetWidth  = window.outerWidth;
      else                               targetWidth  = Math.min(window.innerWidth,  window.outerWidth);  // chromeでinnerがouterを超える事があるので、少ない方を取得
      if      (window.outerHeight === 0) targetHeight = window.innerHeight;
      else if (window.innerHeight === 0) targetHeight = window.outerHeight;
      else                               targetHeight = Math.min(window.innerHeight, window.outerHeight); // chromeでinnerがouterを超える事があるので、少ない方を取得
      const isPortrait = targetWidth <= targetHeight;

      const baseWindowWidth  = isPortrait ? UserWindowSize.BASE_WINDOW_WIDTH : UserWindowSize.BASE_WINDOW_HEIGHT;
      const baseWindowHeight = isPortrait ? UserWindowSize.BASE_WINDOW_HEIGHT : UserWindowSize.BASE_WINDOW_WIDTH;

      const ratioW   = targetWidth / baseWindowWidth;
      const ratioH   = targetHeight / baseWindowHeight;
      const tmpRatio = ratioW < ratioH ? ratioW : ratioH;
      const ratio    = Math.floor(UserWindowSize.BASE_FONT_SIZE * tmpRatio) / UserWindowSize.BASE_FONT_SIZE;

      document.documentElement.style.fontSize = UserWindowSize.BASE_FONT_SIZE * ratio + 'px';
      if (isPortrait) {
        document.body.classList.add('po');
        document.body.classList.remove('lo');
      } else {
        document.body.classList.add('lo');
        document.body.classList.remove('po');
      }

      $_windowSize.ratio  = ratio;
      $_windowSize.width  = targetWidth;
      $_windowSize.height = targetHeight;

      closeLoading();
    };

    /** 画面リサイズイベントハンドリング */
    const onResizeHandler = (): void => {
      openLoading();
      clearTimeout(timer);
      timer = setTimeout(() => adjustWindow(), 200);
    };

    // 画面サイズ調整初回実行
    adjustWindow();

    // 各種設定
    Vue.prototype.$_windowSize = $_windowSize;
    window.onresize = onResizeHandler;
  }
};
