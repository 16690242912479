/** 画面サイズnamespace */
export namespace UserWindowSize { // eslint-disable-line

  /** 画面サイズ情報型定義 */
  export interface WindowSizeData {
    ratio:  number
    width:  number
    height: number
  }

  /** 基準フォントサイズ */
  export const BASE_FONT_SIZE = 10;

  /** 基準ウィンドウ幅 */
  export const BASE_WINDOW_WIDTH = 400;

  /** 基準ウィンドウ高さ */
  export const BASE_WINDOW_HEIGHT = 640;

}
