import mixins from 'vue-typed-mixins';

import OrgRoomMixin from '@/user/mixins/OrgRoomMixin';
import { PublicRoutes } from '@/user/router/AppRouteConfig';
import { PUBLIC_PATH } from '@/classes/common/Constants';



export default mixins(OrgRoomMixin).extend({

  name: 'User',

  // vue-head
  head: {
    title(): Record<string, string> {
      return { inner: this.createHtmlTitle() };
    },
    link(): Record<string, string>[] {
      const links = [];
      links.push({ rel: 'preload', as: 'font', type: 'font/ttf', href: require('@/assets/font/MTkjk4.ttf') });
      links.push({ rel: 'stylesheet', href: `${PUBLIC_PATH}resource/user/css/color.css`, id: 'color' });
      if (this.resourceName.length > 0) {
        links.push({ rel: 'shortcut icon', href: `${PUBLIC_PATH}resource/user/icon/favicon_${this.resourceName}.png`, id: 'icon' });
        links.push({ rel: 'stylesheet',    href: `${PUBLIC_PATH}resource/user/css/color_${this.resourceName}.css`, id: 'color' });
      }
      return links;
    }
  },

  watch: {
    // OrgRoomMixinのstateを監視し、変更があった場合vue-headを更新する
    resourceName(newValue: string, oldValue: string): void {
      if (newValue !== oldValue) this.$emit('updateHead');
    }
  },

  created(): void {
    // ログイン画面以外でリロードした時、storageからデータを取得しstateに設定する
    if (this.$route.name !== PublicRoutes.Login.name) this.loadOrgRoomState();
  },

  methods: {
    createHtmlTitle(): string {
      let ret = '';
      if      (this.isDefault) ret = 'プログラミング先生';
      else if (this.isManabi)  ret = 'プログラミング先生';
      else if (this.isGoogle)  ret = 'プログラミング先生';

      return ret;
    }
  }

});
