import Vue from 'vue';
import Router from 'vue-router';
import moment from 'moment';
import 'core-js/stable';
import '@/plugins/element.js';
import VueHead from 'vue-head';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { HttpStatusCode } from '@/openapi/enum';

import '@/filters';
import App from '@/user/App.vue';
import router from '@/user/router';
import { PublicRoutes } from '@/user/router/AppRouteConfig';
import WindowAdjustPlugin from '@/user/plugins/WindowAdjustPlugin';
import { MOMENT_DEFAULT_FORMAT } from '@/classes/common/Constants';
import { ErrorResponse } from '@/openapi/common';
import { AxiosResponse } from 'axios';


Vue.config.productionTip = false;

// fontawesome
library.add(fas, far);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// modules
Vue.use(VueHead, {separator:''});

// custom plugin
Vue.use(WindowAdjustPlugin);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

// ブラウザのtouchmoveによるスクロール抑止
window.addEventListener('touchmove', (e) => {
  e.preventDefault();
});

// moment.format() のデフォルトフォーマット設定
moment.defaultFormat = MOMENT_DEFAULT_FORMAT;


/**
 * エラー画面への遷移
 *
 * @param router
 */
const pushErrorRoute = (router: Router): void => {
  router.push(PublicRoutes.Exception);
};

/**
 * レスポンスが認可エラーかチェックします。
 *
 * @param response
 * @return boolean
 */
const isUnauthorized = (response: Response): boolean => {
  return response && response.status === HttpStatusCode.unauthorized;
};

/**
 * エラー画面への遷移
 *
 * @param response
 * @param router
 */
const errorPage = (response: Response, router: Router): void => {
  // 認可エラー
  if (isUnauthorized(response)) {
    const errorResponse: ErrorResponse = (response as unknown as AxiosResponse).data;
    router.push({
      name: PublicRoutes.Unauthorized.name,
      query: { message: errorResponse.message }
    });
    return;
  }

  pushErrorRoute(router);
};

/**
 * エラー処理
 * `info` は Vue 固有のエラー情報です（例： どのライフサイクルフックでエラーが起きたかなど）。
 * watch、ライフサイクルイベントは検知できる
 * 非同期処理内、methodsでのエラーは検知できない
 * 非同期処理をasync/await化すると検知できる
 */
Vue.config.errorHandler = (err, vm, info) => {
  console.error('Captured in Vue.config.errorHandler');
  console.error(err);
  console.error(info);
  errorPage((err as any).response, router); // eslint-disable-line @typescript-eslint/no-explicit-any
};

/**
 * setTimeoutやsetIntervalといったネイティブな非同期処理内でのエラーを検知
 */
window.addEventListener('error', event => {
  console.error('Captured in error EventListener', event.error);
  // IE独自エラー対応（無視しても動作に支障なし）
  if (event.error.name === 'SecurityError') return;
  // ChromeのResizeObserver対応
  if (event.message.indexOf('ResizeObserver') > -1) return;
  pushErrorRoute(router);
});

/**
 * Promise内のエラーを検知
 */
window.addEventListener('unhandledrejection', event => {
  console.error('Captured in unhandledrejection EventListener', event.reason);
  errorPage(event.reason.response, router);
});
