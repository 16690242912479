import Vue from 'vue';
import Router from 'vue-router';

import AppRouteConfig from '@/user/router/AppRouteConfig';

Vue.use(Router);

export default new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  // スクロールポジションは全てトップにする
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: AppRouteConfig.getVueRouterConfig()
});
